// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    Form,
    FormField,
    FormFieldText,
    Icon,
    Popover,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as apiTokenActions from 'admin/apiToken/actions';
import * as tagActions from 'admin/tag/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { IApiTokenPatchRequest } from 'common/api/resources/ApiToken';
import {
    requiredRule,
    validate,
} from 'common/validator';
import { Button } from 'admin/common/components/Button/Button';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { EntityTagInputs } from 'admin/tag/components/EntityTagInputs';
import { ItemsLine } from 'common/components/styles/ItemsLine';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { FEATURES } from 'common/modules/app/features/constants';
import { EntityType } from 'common/api/resources/Tag';

interface IApiTokenEditFormProps {
    onSubmit: () => void;
}

type ApiTokenEditFormRequest = IApiTokenPatchRequest & {
    tag_value_ids: Array<number|undefined>;
};

export type ApiTokenEditFormProps =
    IApiTokenEditFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ApiTokenEditForm: React.FC<ApiTokenEditFormProps> = ({
    item,
    taggableEntity,
    isSaving,
    formErrors,
    canManageTags,
    apiTokenActions: {
        patchApiToken,
        unsetApiToken,
    },
    tagActions: {
        updateEntityTags,
        unsetTaggableEntity,
    },
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    onSubmit,
}) => {
    const [submitValues, setSubmitValues] = React.useState<ApiTokenEditFormRequest>({
        name: item.name,
        tag_value_ids: taggableEntity.tags.map(t => t.value.id),
    });

    const handleSubmit = async (values: ApiTokenEditFormRequest) => {
        const errors = validate<ApiTokenEditFormRequest>(values, {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            ...Object.fromEntries(Object.entries(values.tag_value_ids).map(([idx]) =>
                [`tag_value_ids.${idx}`, requiredRule(<Translate content="validate.fieldRequired" />)])),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        await patchApiToken(item.id, {
            name: values.name,
        });

        if (canManageTags) {
            await updateEntityTags({
                id: item.id,
                type: EntityType.TOKEN,
                tag_value_ids: values.tag_value_ids as number[],
            });
        }
        onSubmit();
    };

    const handleFieldChange = (field: string, value: string) => {
        setSubmitValues(setIn(submitValues, field, value));
    };

    const handleTagValueIdsChange = (tagValueIds: Array<number|undefined>) => {
        clearFormErrors();
        setSubmitValues({
            ...submitValues,
            tag_value_ids: tagValueIds,
        });
    };

    React.useEffect(() => () => {
        clearFormErrors();
        unsetApiToken();
        unsetTaggableEntity();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form
            id="apiTokenForm"
            values={submitValues}
            errors={formErrors}
            onSubmit={handleSubmit}
            onFieldChange={handleFieldChange}
            footerClassName="hidden"
            hideRequiredLegend={true}
            submitButton={false}
            cancelButton={false}
            applyButton={false}
            vertical={true}
        >
            <Section>
                <FormFieldText
                    name="name"
                    autoFocus={true}
                    size={SIZE.FILL}
                    label={<Translate content="apiToken.form.name"/>}
                    required={true}
                />
                {canManageTags && (
                    <FormField
                        label={
                            <ItemsLine>
                                <Translate content="apiToken.form.tags" /><br/>
                                <Popover
                                    target={(
                                        <Icon name={ICONS.INFO_CIRCLE} size="12" />
                                    )}>
                                    <Translate content="apiToken.form.tagsInfo" />
                                </Popover>
                            </ItemsLine>
                        }
                    >
                        <EntityTagInputs
                            tags={taggableEntity.tags}
                            onChange={handleTagValueIdsChange}
                            emptyViewText={<Translate content="apiToken.form.tagsEmptyViewText" />}
                        />
                    </FormField>
                )}
            </Section>
            <Button
                type="submit"
                isLoading={isSaving}
                form="apiTokenForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
            >
                <Translate content="apiToken.form.saveBtn"/>
            </Button>
        </Form>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.apiToken.item,
    taggableEntity: state.tag.entity,
    formErrors: state.app.formErrors,
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_AUTH_API_TOKEN) ||
        state.app.loadingFlags.has(LOADING_FLAGS.TAGGABLE_ENTITY_SAVE),
    canManageTags: hasPermission(state, PERMISSION_LIST.MANAGE_TAGS) && state.app.features.has(FEATURES.TAGS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    apiTokenActions: bindActionCreators(apiTokenActions, dispatch),
    tagActions: bindActionCreators(tagActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokenEditForm);
